import components from './modules/components/zh_CN.json';
import customer from './modules/customer/zh_CN.json';
import eas from './modules/eas/zh_CN.json';
import finance from './modules/finance/zh_CN.json';
import home from './modules/home/zh_CN.json';
import inv from './modules/inv/zh_CN.json';
import Login from './modules/Login/zh_CN.json';
import logistics from './modules/logistics/zh_CN.json';
import masterdata from './modules/masterdata/zh_CN.json';
import news from './modules/news/zh_CN.json';
import notice from './modules/notice/zh_CN.json';
import pur from './modules/pur/zh_CN.json';
import sale from './modules/sale/zh_CN.json';
import Tab from './modules/Tab/zh_CN.json';
import tbm from './modules/tbm/zh_CN.json';
import workFlow from './modules/workFlow/zh_CN.json';

export default {
  components,
  customer,
  eas,
  finance,
  home,
  inv,
  Login,
  logistics,
  masterdata,
  news,
  notice,
  pur,
  sale,
  Tab,
  tbm,
  workFlow
};
