import Vue from 'vue';
import Router from 'vue-router';
import businessRouter from '@/views/business/router/index.js';
import { getQueryVariable, ssoLogin, pseudoSync,getSysDict,findUserInfo } from '../util/util';
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
let Home = {};
console.log('process.env.VUE_HOME_SRC', process.env);
if (process.env.VUE_APP_HOME_SRC === 'sys/home') {
  Home = () => import(/* webpackChunkName: "Home" */ '@/views/sys/Home/Home');
} else {
  Home = () => import(/* webpackChunkName: "Home" */ '@/views/business/views/home/home');
}
const User = () => import(/* webpackChunkName: "User" */ '@/views/sys/User/User');
const login = () => {
  if (process.env.VUE_APP_LOGIN_SRC === 'business/login') {
    return import(/* webpackChunkName: "Login" */ '@/views/business/views/Login/Login');
  } else {
    return import(/* webpackChunkName: "Login" */ '@/views/sys/Login/Login');
  }
};
const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/views/sys/NotFound/NotFound');

const todoList = () => import(/* webpackChunkName: "todoList" */ '@/views/sys/todoList/todoList');
const todoDetail = () => import(/* webpackChunkName: "todoList" */ '@/views/sys/todoList/detail');

const haveDoneList = () => import(/* webpackChunkName: "todoList" */ '@/views/sys/haveDone/haveDoneList');
const haveDoneDetail = () => import(/* webpackChunkName: "todoList" */ '@/views/sys/haveDone/haveDoneDetail');

const addressBookList = () => import(/* webpackChunkName: "addressBookList" */ '@/views/sys/addressBook/list');

const approvalPanel = () => import(/* webpackChunkName: "addressBookList" */ '@/components/approval/approvalPanel');

const messageList = () => import(/* webpackChunkName: "messageList" */ '@/views/sys/Message/messageList');
const noticeList = () => import(/* webpackChunkName: "messageList" */ '@/views/sys/NewsNotice/noticeList');
const projectList = () => import(/* webpackChunkName: "messageList" */ '@/views/sys/Project/projectList');

const messageView = () => import(/* webpackChunkName: "messageList" */ '@/views/sys/msgViews/index');
Vue.use(Router);

export const router = new Router({
  routes: [
    {
      path: '*',
      name: 'Notfound',
      component: NotFound,
      meta: {
        fullScreen: true
      }
    },
    {
      path: '/',
      name: 'Login',
      component: login,
      meta: {
        title: '登录',
        fullScreen: true
      }
    },
    {
      path: '/Home',
      name: 'Home',
      component: Home,
      meta: {
        title: '中建材海外自助平台',
      }
    },
    {
      path: '/User',
      name: 'User',
      component: User
    },
    {
      path: '/Login',
      name: 'Login',
      component: login,
      meta: {
        title: '登录',
        fullScreen: true
      }
    },
    {
      path: '/todoList',
      name: 'todoList',
      component: todoList,
      meta: {
        fullScreen: true
      }
    },
    {
      path: '/todoDetail',
      name: 'todoDetail',
      component: todoDetail,
      meta: {
        fullScreen: true
      }
    },
    {
      path: '/haveDoneList',
      name: 'haveDoneList',
      component: haveDoneList,
      meta: {
        fullScreen: true
      }
    },
    {
      path: '/haveDoneDetail',
      name: 'haveDoneDetail',
      component: haveDoneDetail,
      meta: {
        fullScreen: true
      }
    },
    {
      path: '/addressBookList',
      name: 'addressBookList',
      component: addressBookList,
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/approvalPanel',
      name: 'approvalPanel',
      component: approvalPanel,
      meta: {
        fullScreen: true,
        title:'审批'
      }
    },
    {
      path: '/messageList',
      name: 'messageList',
      component: messageList
    },
    {
      path: '/noticeList',
      name: 'noticeList',
      component: noticeList,
      meta: {
        fullScreen: true
      }
    },
    {
      path: '/projectList',
      name: 'projectList',
      component: projectList,
      meta: {
        fullScreen: true
      }
    },
    {
      path: '/messageView',
      name: 'messageView',
      component: messageView,
      meta: {
        fullScreen: true
      }
    },
    ...businessRouter
  ],
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  }
});

router.beforeEach((to, from, next) => {
  //截取到url中，获取到sso登陆标识，走单点登录逻辑
  window.document.title = to.meta.title;
  let token = sessionStorage.getItem('token');
  if(!token){
    let type = getQueryVariable("login");
    if(null == type){
      //正常登陆逻辑
      if (!token && to.path != '/') {
        return next({
          name: 'Login'
        });
      }
      next();
    }else if("weChat"== type){
      let token = getQueryVariable("token");
      let userName = getQueryVariable("userName");
      let loginName = getQueryVariable("loginName");
      if (token && userName && loginName) {
        sessionStorage.token = token;
        sessionStorage.userName = userName;
        sessionStorage.loginName = loginName;
        pseudoSync(async()=>{
          let dirRes = await getSysDict();
          let userRes = await findUserInfo();
          // localStorage.setItem('sysDict',JSON.stringify(dirRes));
          localStorage.setItem('sysDict', JSON.stringify(dirRes.data.rows));
          sessionStorage.cname = userRes.data.entity.cname;
          //localStorage.setItem('userInfo',JSON.stringify(userRes));
          localStorage.setItem('userInfo', JSON.stringify(Object.assign(userRes.data.entity, userRes.data.ext)))
          console.log(JSON.stringify(userRes));
          console.log(JSON.parse(localStorage.getItem('userInfo')).companyId)
        })
      }
      next();
    }else{
      //正常登陆逻辑
      if (!token && to.path != '/') {
        return next({
          name: 'Login'
        });
      }
      next();
    }

    // if(null == type){
    //     //正常登陆逻辑
    //     if (!token && to.path != '/') {
    //       return next({
    //         name: 'Login'
    //       });
    //     }
    //     next();
    // }else if("wx"== type){
    //   // 企业微信登录
    //   let url="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwa6585db61a47eb4d&redirect_uri=http://111.205.100.131:3134/microarch/auth/weChatLogin&response_type=code&scope=snsapi_userinfo&agentid=1000007&state=STATE#wechat_redirect";
    //   window.location.href = url;
    // }else if("autoLogin"== type){
    //   // 自动登录
    //   let token = getQueryVariable("token");
    //   let userName = getQueryVariable("userName");
    //   let loginName = getQueryVariable("loginName");
    //   if (token && userName && loginName) {
    //     sessionStorage.token = token;
    //     sessionStorage.userName = userName;
    //     sessionStorage.loginName = loginName;
    //     pseudoSync(async()=>{
    //       let dirRes = await getSysDict();
    //       let userRes = await findUserInfo();
    //       // localStorage.setItem('sysDict',JSON.stringify(dirRes));
    //       localStorage.setItem('sysDict', JSON.stringify(dirRes.data.rows));
    //       sessionStorage.cname = userRes.data.entity.cname;
    //       //localStorage.setItem('userInfo',JSON.stringify(userRes));
    //       localStorage.setItem('userInfo', JSON.stringify(Object.assign(userRes.data.entity, userRes.data.ext)))
    //       console.log(JSON.stringify(userRes));
    //       debugger;
    //       console.log(JSON.parse(localStorage.getItem('userInfo')).companyId)
    //     })

    //     return next({
    //       name: 'Home'
    //     });
    //   } else {
    //     //正常登陆逻辑
    //     let token = sessionStorage.getItem('token');
    //     if (!token && to.path != '/') {
    //       return next({
    //         name: 'Login'
    //       });
    //     }
    //     next();
    //   }

    // }else{
    // }
  }else{
    //正常登陆逻辑
    if (!token && to.path != '/') {
      return next({
        name: 'Login'
      });
    }
    next();
  }

});
