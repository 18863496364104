const state = {
  good: {},
  orderGood: {},
  addressList: [],
  editAddress: {
    Username: '',
    Addressid: '',
    Name: '',
    Phonenum: '',
    Address: '',
    AddressDetail: '',
    PostalCode: '',
    Isdefault: 0
  }
};
export default state;
