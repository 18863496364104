import i18n from '@/i18n';
const LOCALE = 'locale';

const state = {
  locale: localStorage.getItem(LOCALE) || 'zh_CN'
};

const getters = {
  locale: state => state.locale
};

const mutations = {
  modifyLocale(state, locale) {
    state.locale = locale;
    localStorage.setItem(LOCALE, locale);
    i18n.locale = locale;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
