import Vue from 'vue';
import FileView from "./FileView.vue";

// 构造组件
const FileViewConstructor = Vue.extend(FileView);

const FileViewDiv = (options) => {
  // 实例化组件
  const instance = new FileViewConstructor({
    el: document.createElement('div'),
    // 组件参数，运用到组件内的data
    data: options,
  });
  // 在body添加组件
  document.body.appendChild(instance.$el);
  return instance;
};

export default FileViewDiv;
