//常用工具方法
import axios from 'axios';


let util = {};

const ajax = axios.create({
  timeout: 30000,
  headers: {Authorization: 'Sys ' + sessionStorage.getItem('token')}
});

/**
 * 关闭当前页签方法
 * @param name 当前页签路由name
 * @param vm   传入 this
 */
util.closeCurrentTag = (name, vm) => {
  let pageOpenedList = vm.$store.state.app.pageOpenedList;
  let lastPageObj = pageOpenedList[0];
  if (name) {
    let len = pageOpenedList.length;
    for (let i = 1; i < len; i++) {
      if (pageOpenedList[i].name === name) {
        if (i < (len - 1)) {
          lastPageObj = pageOpenedList[i + 1];
        } else {
          lastPageObj = pageOpenedList[i - 1];
        }
        break;
      }
    }
  }
  vm.$store.commit('removeTag', name);
  vm.$store.commit('closePage', name);
  pageOpenedList = vm.$store.state.app.pageOpenedList;
  localStorage.pageOpenedList = JSON.stringify(pageOpenedList);
  let routerObj = {};
  routerObj.name = lastPageObj.name;
  if (lastPageObj.argu) {
    routerObj.params = lastPageObj.argu;
  }
  if (lastPageObj.query) {
    routerObj.query = lastPageObj.query;
  }
  vm.$router.push(routerObj);
};

export function fun_date (day) {
  var date1 = new Date();
  var date2 = new Date(date1);
  date2.setDate(date1.getDate() + day);
  var time2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
  return time2;
}
export function getQueryVariable (name) {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || ['', ''])[1].replace(/\+/g, '%20')
    ) || null
  );
}

export function pseudoSync(callBack) {
  if (callBack) {
    callBack();
  }
}



export function getSysDict() {
  return ajax({
    method: 'POST',
    headers: {Authorization: 'Sys ' + sessionStorage.getItem('token')},
    url: '/microarch/sys/sysDictHead/find',
    data: {entity: {lang: 'zh_CN'}}
  });
}

export function findUserInfo() {
  return ajax({
    method: 'POST',
    headers: {Authorization: 'Sys ' + sessionStorage.getItem('token')},
    url: '/microarch/sys/sysuser/findUserInfo',
    data: {"entity": {"loginName": sessionStorage.getItem("loginName"), "lang": "zh_CN"}}
  });
}

export function ssoLogin (uri) {
  // todo 根据第三方tocken 接口请求获取到自己的tocken等信息
  // system 需要与后台配置文件中authUrl、accessToken配置保持一致
  axios
    ({
      method: 'get',
      url: process.env.VUE_APP_SSO_LOGIN_URL + '?token=' + getQueryVariable('mx_sso_token') + '&system=system1'
    })
    .then(res => {
      if (!res.data.errMsg) {
        sessionStorage.token = res.data.token;
        sessionStorage.cname = res.data.user;
        sessionStorage.userName = res.data.userInfo.userName;
        sessionStorage.userInfo = JSON.stringify(res.data.userInfo);
      } else {
        sessionStorage.token = "mx_token";
      }

      // 跳转到路由页面
      window.vm.$router.push({
        name: uri
      });

      // window.vm.$myHttp({
      //   method: 'POST',
      //   url: '/microarch/sys/sysDictHead/find',
      //   data: {
      //     entity: {
      //       lang: 'zh_CN'
      //     }
      //   }
      // }).then(res => {
      //   localStorage.setItem('sysDict', JSON.stringify(res.data.rows));
      //   window.vm.$router.push({
      //     name: 'Home'
      //   });
      // });


    });
}
export default util;
