import components from './modules/components/en_US.json';
import customer from './modules/customer/en_US.json';
import eas from './modules/eas/en_US.json';
import finance from './modules/finance/en_US.json';
import home from './modules/home/en_US.json';
import inv from './modules/inv/en_US.json';
import Login from './modules/Login/en_US.json';
import logistics from './modules/logistics/en_US.json';
import masterdata from './modules/masterdata/en_US.json';
import news from './modules/news/en_US.json';
import notice from './modules/notice/en_US.json';
import pur from './modules/pur/en_US.json';
import sale from './modules/sale/en_US.json';
import Tab from './modules/Tab/en_US.json';
import tbm from './modules/tbm/en_US.json';
import workFlow from './modules/workFlow/en_US.json';

export default {
  components,
  customer,
  eas,
  finance,
  home,
  inv,
  Login,
  logistics,
  masterdata,
  news,
  notice,
  pur,
  sale,
  Tab,
  tbm,
  workFlow
};
