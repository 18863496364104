<template>
  <div class="fileview">
    <van-nav-bar :title="fileName" fixed @click-right="onClose">
      <template #right>
        <van-icon name="close" />
      </template>
    </van-nav-bar>
    <div class="container">
      <iframe class="fileContainer" :src="fileUrl" title="W3Schools Free Online Web Tutorials"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileName: "文件预览",
      fileUrl: ''
    };
  },
  methods: {
    onClose(){
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>

<style scoped>
.fileview {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  /*border-radius: 10px;*/
  animation: messageFade 3s 1;
}
.fileContainer {
  position: absolute;
  border: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.container {
  position: relative;
  margin-top: 46px;
  width: 100%;
  height: 100%;
}
</style>
