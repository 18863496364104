import VueI18n from 'vue-i18n';

import zh_CN from './zh_CN';
import en_US from './en_US';
import Vue from 'vue';

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem('locale') || 'zh_CN',
  messages: { zh_CN, en_US }
});
