/**
 * 数字字符常量
 * @type {{STR0: string, STR1: string, STR2: string, STR3: string, STR4: string, STR5: string, STR6: string, STR7: string, STR8: string, STR9: string}}
 */
const STR = {
  STR0: '0',
  STR1: '1',
  STR2: '2',
  STR3: '3',
  STR4: '4',
  STR5: '5',
  STR6: '6',
  STR7: '7',
  STR8: '8',
  STR9: '9'
};
/**
* 表单状态
* @type {{ABOLISH: string, EFFECT: string, PREPARE: string, SUBMIT: string, FORCE: string}}
*/
const FormState =
  {
      TEMPORARY : STR.STR0,// 暂存Temporary
      INAUDIT: STR.STR1,// 审核中In audit
      TOVOID: STR.STR2,// 作废
      AUDITED: STR.STR3,// 通过审核
  };
/**
* 页面类型
* @type {{VIEW: string, EDIT: string, APPROVAL: string}}
*/
const PageType = {
  VIEW: "View",
  EDIT: "Edit",
  APPROVAL: "Approval"
};
/**
* 任务类型
* @type {{TRANSLATED: string, WAIT_FOR: string, TO_READ: string, READ: string}}
*/
const TaskType = {
  TRANSLATED: "transated",// 已办
  WAIT_FOR: "waitfor",// 待办
  TO_READ: "read",// 待阅
  READ: "readed",// 已阅
};
/**
* 返回路径
* @type {{TODO_LIST: string, HAVE_LIST: string, ENCY_LIST: string, HAVE_ENCY_LIST: string}}
*/
const ReturnName = {
  TODO_LIST: "sys/workFlow/toDoList",// 待办列表
  HAVE_LIST: "sys/workFlow/haveList",// 已办列表
  ENCY_LIST: "sys/workFlow/encyclicList",// 待阅列表
  HAVE_ENCY_LIST: "sys/workFlow/encyclicReadedList",// 已阅列表
};

/**
* 模板类型
* @type {{ENTRY: string, REGISTER: string, COLLECT: string}}
*/
const TemplateType = {
  ENTRY: STR.STR1,// 入职
  REGISTER: STR.STR2,// 注册
  COLLECT: STR.STR3// 人员信息采集
};

/**
* 业务编码
* @type {{HiJoinApply: string, HiPsnDoc: string, HiPsnInvite: string, HiPsnJob: string, HiRegularApply: string, HiTransfer: string, OmJob: string, OmPost: string, HiEntryApply: string, HiLeave: string, HiItemHandle: string}}
*/
const BizCode = {
  TbmApplyOvertime: 'TbmApplyOvertime',
  TbmSummaryReport: 'TbmSummaryReport',
  ApplyUnusual: 'ApplyUnusual,',
  ApplyHoliday: 'ApplyHoliday',
  ApplyOfficeout: 'ApplyOfficeout'


};

const OvertimeType = {
  workingdDay: '1',// 工作日
  legalholidays: '0', //法定节假日
  restDay: '2' //休息日
};

const DefalutAttentions =
  {
      defalutMessage : '原则上应事先向相关领导请假，因不可预见的疾病或急事等意外情况未能及时提交请假流程的 <br/>须先口头或短信请假，并于返回工作岗位后一个工作日补办手续.'

  };

/**
* 调动流程类型
* @type {{TransCroCorp: {type: string, name: string, route: {view: string, edit: string}}, TransTemp: {type: string, name: string, route: {view: string, edit: string}}, TransCroDept: {type: string, name: string, route: {view: string, edit: string}}, TransInternship: {type: string, name: string, route: {view: string, edit: string}}, TransPromote: {type: string, name: string, route: {view: string, edit: string}}}}
*/
const TransProcType = {
  TransCroCorp: {
      type: 'TransCroCorp',
      name: '系统内调入',
      route: {
          view: 'hi/transfer/croCorpView',
          edit: 'hi/transfer/croCorpEdit',
      }
  },
  TransTemp: {
      type: 'TransTemp',
      name: '员工借调',
      route: {
          view: 'hi/transfer/tempView',
          edit: 'hi/transfer/tempEdit',
      }
  },
  TransCroDept: {
      type: 'TransCroDept',
      name: '跨部门调动',
      route: {
          view: 'hi/transfer/croDeptView',
          edit: 'hi/transfer/croDeptEdit',
      }
  },
  TransInternship: {
      type: 'TransInternship',
      name: '基层实习锻炼',
      route: {
          view: 'hi/transfer/internshipView',
          edit: 'hi/transfer/internshipEdit',
      }
  },
  TransPromote: {
      type: 'TransPromote',
      name: '晋升',
      route: {
          view: 'hi/transfer/promoteView',
          edit: 'hi/transfer/promoteEdit',
      }
  }
};
/**
* 转正流程类型
* @type {{RegularInternship: {type: string, name: string, route: {view: string, edit: string}}, RegularProbation: {type: string, name: string, route: {view: string, edit: string}}}}
*/
const RegularProcType = {
  RegularInternship: {
      type: 'RegularInternship',
      name: '试用期转正',
      route: {
          view: 'hi/regular/internshipView',
          edit: 'hi/regular/internshipEdit',
      }
  },
  RegularProbation: {
      type: 'RegularProbation',
      name: '见习期转正',
      route: {
          view: 'hi/regular/probationView',
          edit: 'hi/regular/probationEdit',
      }
  },
};
/**
* 调动来源:组织人事部发起\用人部门发起/员工发起
* @type {{Organization: string, Department: string, Employee: string}}
*/
const TransSource = {
  Organization: 'Organization',// 组织人事部
  Department: 'Department',// 用人部门
  Employee: 'Employee',// 员工
};
/**
* 审核状态
* @type {{wait: string, agree: string, disagree: string}}
*/
const ReviewState = {
  wait: STR.STR0 + STR.STR1,// 未审核
  agree: STR.STR0 + STR.STR2,// 同意
  disagree: STR.STR0 + STR.STR3// 不同意
};

/**
* 请假申请类型（审批）
*/
const leaveType = {
  MaternityLeave: 'TbmMaternityLeave',// 生育陪产
  FuneralLeave: 'TbmFuneralLeave',// 婚丧探亲
  AnnualLeave: 'TbmAnnualLeave', // 公假年休假
  SickLeave: 'TbmSickLeave', //事假 病假
  InjuryLeave: 'TbmInjuryLeave', //工伤假
  VacationLeave: 'TbmVacationLeave', //调休假
  GroupLeaderLeave: 'TbmGroupLeaderLeave', //集团领导请假
  ChaperoneLeave : 'TbmChaperoneLeave' //陪护假
};

/**
* 流程操作类型
* @type {{REPEL_PROCESS: string, FORCE_END_PROCESS: string, END_PROCESS: string, SUBMIT_PROCESS: string, Before_Add_Sign: string, After_Add_Sign: string, RETURN: string}}
*/
const OptionType = {
  REPEL_PROCESS: "repelProcess",// 撤销审批
  FORCE_END_PROCESS: "forceEndProcess",// 强制结束
  END_PROCESS: "endProcess",// 完成审批
  SUBMIT_PROCESS: "submitProcess",// 提交审批
  Before_Add_Sign: 'beforeAddsign',// 前加签
  After_Add_Sign: 'afterAddsign',// 后加签
  RETURN: 'return',// 驳回
};

const deptAndPost={
 CEO : 5,
 CEOSENCOND : 6,
 CHAIRMANSENCODE :8 
}


const holidayType = {
   HOLIDAY_TYPE_LEAVE :"1", // 事假
   HOLIDAY_TYPE_SICK : "2", // 病假
   HOLIDAY_TYPE_WEDDING : "3", // 婚假
   HOLIDAY_TYPE_MATERNITY : "4", // 生育假
   HOLIDAY_TYPE_FUNERAL : "5", // 丧假
   HOLIDAY_TYPE_ANNUAL : "6", // 年休假
   HOLIDAY_TYPE_FAMILY : "7", // 探亲假
   HOLIDAY_TYPE_PUBLIC : "8", // 公假
   HOLIDAY_TYPE_INJURY : "9", // 工伤假
   HOLIDAY_TYPE_ADJUST : "10", // 调休假
   HOLIDAY_TYPE_OTHER :"11", // 其他
   HOLIDAY_TYPE_PATERNITY : "12", // 陪产假
   HOLIDAY_TYPE_CHAPERONE: "13", //陪护假
};

/**
* 公共
* @type {{Y: string, N: string}}
*/
const ComPub = {
  Y: 'Y',
  N: 'N'
};
export
default
{
  FormState,
  PageType,
  OptionType,
  TaskType,
  ReturnName,
  STR,
  TemplateType,
  BizCode,
  ComPub,
  TransProcType,
  TransSource,
  RegularProcType,
  ReviewState,
  leaveType,
  holidayType,
  deptAndPost,
  DefalutAttentions,
  OvertimeType
}
