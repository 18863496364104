import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import i18n from './modules/i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { i18n },
  state,
  getters,
  mutations
});
