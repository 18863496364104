<template>
  <div>
    <van-tabbar v-model="active" class="tab" style="z-index:999">
      <van-tabbar-item icon="home-o" to="/Home">首页</van-tabbar-item>
      <van-tabbar-item v-if="mesCount > 0" icon="chat-o" to="/messageList" :info="mesCount">消息</van-tabbar-item>
      <van-tabbar-item v-else icon="chat-o" to="/messageList">消息</van-tabbar-item>
      <van-tabbar-item icon="friends-o" to="/addressBookList">通讯录</van-tabbar-item>
      <van-tabbar-item icon="user-circle-o" to="/User">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0
    };
  },
  props: {
    mesCount: ''
  },
  watch: {
    $route(to, from) {
      //this.getMessage();
      switch (to.fullPath) {
        case '/':
          this.active = 0;
          break;
        case '/messageList':
          this.active = 1;
          break;
        case '/addressBookList':
          this.active = 2;
          break;
        case '/User':
          this.active = 3;
          break;
        default:
          this.active = 0;
      }
    }
  }
};
</script>
