import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import { Lazyload } from 'vant';
import 'vant/lib/index.less';
import { router } from './router';
import store from './store';
import axios from 'axios';
import './util/http';
import './util/validate';
import './main.less';
import * as filters from './filters';
import Vuelidate from "vuelidate/src";
import i18n from './i18n';

import EhrUtils from '@/views/business/libs/EhrUtils.js';
import TbmCons from '@/views/business/libs/TbmCons.js';
import FilePrvwUtils from '@/views/business/libs/FilePrvwUtils.js';

axios.defaults.withCredentials = true;

Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.prototype.$myHttp = axios;


Vue.prototype.EhrUtils = EhrUtils;
Vue.prototype.TbmCons = TbmCons;

// 附件预览相关配置
Vue.prototype.$FilePrvwUtils = FilePrvwUtils; // 附件预览工具类

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

window.vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
