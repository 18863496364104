import { ImagePreview } from 'vant';
import FileView from "@/views/business/components/fileview/FileViewJs.js";

var fileViewHost = process.env.VUE_APP_FILE_VIEW_HOST; // 文件预览服务器kkfileview的地址（服务器的真实地址或nginx代理地址）
var fileStoreHost = process.env.VUE_APP_FILE_REAL_HOST; // 文件的真实可下载的地址（后端真实地址或可代理到后端的nginx代理地址）
var fileStoreNetHost = process.env.VUE_APP_FILE_REAL_NET_HOST; // 文件的真实可下载的地址（后端真实地址或可代理到后端的nginx代理地址）

/**
 * 文件预览相关工具方法
 * @type {{}}
 */
let FilePrvwUtils = {};

/**
 * 根据文件名获取文件后缀，不带点号
 * @param fileName 文件名
 * @returns {string} 小写的后缀名，例：docx
 */
FilePrvwUtils.getFileType = function (fileName){
  let index= fileName.lastIndexOf(".");
  //获取后缀
  return fileName.substr(index+1).toLowerCase();
}

/**
 * 生成附件下载直链
 * @param id 附件id
 * @param fileName 预留参数，为后期修改留下余地
 */
FilePrvwUtils.getFileLink = function (id,fileName){
  let url;
  let fileType = FilePrvwUtils.getFileType(fileName);
  // 预览图片
  if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png'){
    url = fileStoreNetHost + '/microarch/sys/sysAttchement/showPdf/' + id +'.' + FilePrvwUtils.getFileType(fileName);
  }else{
    url = fileStoreHost + '/microarch/sys/sysAttchement/showPdf/' + id +'.' + FilePrvwUtils.getFileType(fileName);
  }

  return url;
}

/**
 * 预览 pdf，返回直接打开pdf的链接
 * @param id 附件id
 * @param fileName 完整文件名，带后缀
 */
FilePrvwUtils.getPdfViewLink = function (id,fileName) {
  // 后台返回流的形式
  let url = FilePrvwUtils.getFileLink(id,fileName);
  return location.origin+'/pdf/web/viewer.html?file=' + encodeURIComponent(url);
}

/**
 * 预览文件，根据文件id和文件名生成预览链接
 * @param id 附件id
 * @param fileName 完整文件名，带后缀
 * @returns {string} 预览文件的链接
 */
FilePrvwUtils.getFileViewLink = function (id, fileName) {
  let url = FilePrvwUtils.getFileLink(id,fileName);
  return fileViewHost + '/onlinePreview?url=' + encodeURIComponent(window.btoa(url));
}

/**
 * 直接预览文件
 * @param id 附件id
 * @param fileName 完整文件名称，带后缀，例：test.docx
 */
FilePrvwUtils.openFile = function (id, fileName){
  let fileType = FilePrvwUtils.getFileType(fileName);
  // 预览图片
  if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png'){
    ImagePreview({
      images: [FilePrvwUtils.getFileLink(id,fileName) ],
      closeable: true,
    });
    return;
  }

  // location.href = FilePrvwUtils.getFileViewLink(id,fileName);
  window.open(FilePrvwUtils.getFileViewLink(id,fileName),'_self');
  // 打开预览链接
  // FileView({
  //   fileName: fileName,
  //   fileUrl: FilePrvwUtils.getFileViewLink(id,fileName)
  // });

}

export default FilePrvwUtils;
