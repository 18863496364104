// 引入 axios
import axios from 'axios';
import { Toast } from 'vant';
import { router } from '@/router';
import { showFullScreenLoading, tryHideFullScreenLoading } from '@/util/loading';

// 环境的切换
axios.defaults.baseURL = process.env.VUE_APP_API_HOST + process.env.VUE_APP_PROXY;
//axios.defaults.baseURL = 'api';

// 超时时间
axios.defaults.timeout = 50000;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function(config) {
    if (config.headers.isLoading !== false) {
      // 如果配置了isLoading: false，则不显示loading
      showFullScreenLoading();
    }
    if (sessionStorage.getItem('token')) {
      config.headers.Authorization = 'Sys ' + sessionStorage.getItem('token');
    }
    return config;
  },
  function(error) {
    tryHideFullScreenLoading();
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  response => {
    // 响应成功关闭loading
    tryHideFullScreenLoading();
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          });
          break;
        case 404:
          Toast({
            message: '网络请求不存在',
            duration: 1500,
            forbidClick: true
          });
          break;
        default:
          Toast({
            message: error.response.data.message,
            duration: 1500,
            forbidClick: true
          });
      }
      tryHideFullScreenLoading();
      return Promise.reject(error.response);
    }
  }
);

export default axios;
