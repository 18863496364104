const businessRouter = [
  {
    path: '/notice/noticeList',
    name: 'notice/noticeList',
    meta: {
      title: '通知',
    },
    component: () => import('@/views/business/views/notice/noticeList.vue')
  },
  {
    path: '/notice/noticeDetail',
    name: 'notice/noticeDetail',
    meta: {
      title: '信息查看',
      fullScreen: true
    },
    component: () => import('@/views/business/views/notice/noticeDetail.vue')
  },
  {
    path: '/news/newsList',
    name: 'news/newsList',
    meta: {
      title: '消息',
    },
    component: () => import('@/views/business/views/news/newsList.vue')
  },
  //待办已办待阅已阅
  {
    path: '/workFlow/workFlowList',
    name: 'workFlow/workFlowList',
    meta: {
      title: '事项',
      fullScreen: true
    },
    component: () => import('@/views/business/views/workFlow/workFlowList.vue')
  },
  //考勤确认
  {
    path: '/tbm/selfService/selfServiceList',
    name: 'tbm/selfService/selfServiceList',
    meta: {
      title: '考勤确认',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/selfService/selfServiceList.vue')
  },
  {
    path: '/tbm/selfService/selfServiceDetail',
    name: 'tbm/selfService/selfServiceDetail',
    meta: {
      title: '考勤详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/selfService/selfServiceDetail.vue')
  },
  {
    path: '/tbm/selfService/selfServicePunch',
    name: 'tbm/selfService/selfServicePunch',
    meta: {
      title: '打卡详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/selfService/selfServicePunch.vue')
  },
  //  请假申请
  {
    path: '/tbm/applyHoliday/applyHolidayList',
    name: 'tbm/applyHoliday/applyHolidayList',
    meta: {
      title: '请假申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyHoliday/applyHolidayList.vue')
  },
  {
    path: '/tbm/applyHoliday/applyHolidayEdit',
    name: 'tbm/applyHoliday/applyHolidayEdit',
    meta: {
      title: '请假申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyHoliday/applyHolidayEdit.vue')
  },
  {
    path: '/tbm/applyHoliday/applyHolidayApplyView',
    name: 'tbm/applyHoliday/applyHolidayApplyView',
    meta: {
      title: '请假申请查看',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyHoliday/applyHolidayApplyView.vue')
  },
  {
    path: '/tbm/applyHoliday/applyHolidayChange',
    name: 'tbm/applyHoliday/applyHolidayChange',
    meta: {
      title: '请假申请变更',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyHoliday/applyHolidayChange.vue')
  },
  {
    path: '/tbm/applyHoliday/applyHolidayChangeView',
    name: 'tbm/applyHoliday/applyHolidayChangeView',
    meta: {
      title: '请假变更审批',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyHoliday/applyHolidayChangeView.vue')
  },
  {
    path: '/tbm/applyHoliday/applyHolidayViewTabs',
    name: 'tbm/applyHoliday/applyHolidayViewTabs',
    meta: {
      title: '请假管理',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyHoliday/applyHolidayViewTabs.vue')
  },
  //公出申请
  {
    path: '/tbm/applyOfficeout/applyOfficeoutList',
    name: 'tbm/applyOfficeout/applyOfficeoutList',
    meta: {
      title: '公出申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyOfficeout/applyOfficeoutList.vue')
  },
  {
    path: '/tbm/applyOfficeout/applyOfficeoutEdit',
    name: 'tbm/applyOfficeout/applyOfficeoutEdit',
    meta: {
      title: '公出申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyOfficeout/applyOfficeoutEdit.vue')
  },
  {
    path: '/tbm/applyOfficeout/applyOfficeoutApplyView',
    name: 'tbm/applyOfficeout/applyOfficeoutApplyView',
    meta: {
      title: '公出申请查看',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyOfficeout/applyOfficeoutApplyView.vue')
  },
  {
    path: '/tbm/applyOfficeout/applyOfficeoutChange',
    name: 'tbm/applyOfficeout/applyOfficeoutChange',
    meta: {
      title: '公出申请变更',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyOfficeout/applyOfficeoutChange.vue')
  },
  {
    path: '/tbm/applyOfficeout/applyOfficeoutChangeView',
    name: 'tbm/applyOfficeout/applyOfficeoutChangeView',
    meta: {
      title: '公出申请审批',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyOfficeout/applyOfficeoutChangeView.vue')
  },
  //加班申请
  {
    path: '/tbm/overtimeApplication/overtimeApplicationList',
    name: 'tbm/overtimeApplication/overtimeApplicationList',
    meta: {
      title: '加班申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/overtimeApplication/overtimeApplicationList.vue')
  },
  {
    path: '/tbm/overtimeApplication/overtimeApplicationEdit',
    name: 'tbm/overtimeApplication/overtimeApplicationEdit',
    meta: {
      title: '加班申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/overtimeApplication/overtimeApplicationEdit.vue')
  },
  {
    path: '/tbm/overtimeApplication/overtimeApplicationApplyView',
    name: 'tbm/overtimeApplication/overtimeApplicationApplyView',
    meta: {
      title: '加班申请查看',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/overtimeApplication/overtimeApplicationApplyView.vue')
  },
  {
    path: '/tbm/overtimeApplication/overtimeApplicationDetail',
    name: 'tbm/overtimeApplication/overtimeApplicationDetail',
    meta: {
      title: '加班明细',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/overtimeApplication/overtimeApplicationDetail.vue')
  },
  //异常申请
  {
    path: '/tbm/applyUnusual/applyUnusualList',
    name: 'tbm/applyUnusual/applyUnusualList',
    meta: {
      title: '异常申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyUnusual/applyUnusualList.vue')
  },
  {
    path: '/tbm/applyUnusual/applyUnusualEdit',
    name: 'tbm/applyUnusual/applyUnusualEdit',
    meta: {
      title: '异常申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyUnusual/applyUnusualEdit.vue')
  },
  {
    path: '/tbm/applyUnusual/applyUnusualView',
    name: 'tbm/applyUnusual/applyUnusualView',
    meta: {
      title: '异常申请查看',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyUnusual/applyUnusualView.vue')
  },
  {
    path: '/tbm/applyUnusual/applyUnusualDetail',
    name: 'tbm/applyUnusual/applyUnusualDetail',
    meta: {
      title: '新建异常明细',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyUnusual/applyUnusualDetail.vue')
  },
  {
    path: '/tbm/applyUnusual/selectUnusualList',
    name: 'tbm/applyUnusual/selectUnusualList',
    meta: {
      title: '异常列表',
      fullScreen: true
    },
    component: () => import('@/views/business/views/tbm/applyUnusual/selectUnusualList.vue')
  },
  // 进口费用付款
  {
    path: '/finance/importfeepaymentheader/importFeePaymentHeaderView',
    name: 'finance/importfeepaymentheader/importFeePaymentHeaderView',
    meta: {
      title: '付款申请单',
      fullScreen: true
    },
    component: () => import('@/views/business/views/finance/importfeepaymentheader/importFeePaymentHeaderView.vue')
  },
  // 进口费用付款
  {
    path: '/finance/importfeeinvoiceheader/importFeeInvoiceHeaderEdit',
    name: 'finance/importfeeinvoiceheader/importFeeInvoiceHeaderEdit',
    meta: {
      title: '进项发票',
      fullScreen: true
    },
    component: () => import('@/views/business/views/finance/importfeeinvoiceheader/importFeeInvoiceHeaderEdit.vue')
  },
  // 进口费用付款
  {
    path: '/finance/importfeepaymentheader/importFeePaymentHeaderEdit',
    name: 'finance/importfeepaymentheader/importFeePaymentHeaderEdit',
    meta: {
      title: '付款申请单',
      fullScreen: true
    },
    component: () => import('@/views/business/views/finance/importfeepaymentheader/importFeePaymentHeaderEdit.vue')
  },
  {
    path: '/sale/docClaim/history/docClaimHistoryView',
    name: 'sale/docClaim/history/docClaimHistoryView',
    meta: {
      title: '认款历史记录',
      fullScreen: true
    },
    component: () => import('@/views/business/views/sale/docClaim/history/docClaimHistoryView.vue')
  },
  {
    path: '/sale/docClaim/history/docClaimHistoryEdit',
    name: 'sale/docClaim/history/docClaimHistoryEdit',
    meta: {
      title: '认款历史记录',
      fullScreen: true
    },
    component: () => import('@/views/business/views/sale/docClaim/history/docClaimHistoryEdit.vue')
  },
  // 款项认领
  {
    path: '/sale/docClaim/docClaimEdit',
    name: 'sale/docClaim/docClaimEdit',
    meta: {
      title: '款项认领',
      fullScreen: true
    },
    component: () => import('@/views/business/views/sale/docClaim/docClaimEdit.vue')
  },
  // 境外采购订单审批
  {
    path: '/pur/purCooperative/purCooperativeEdit',
    name: 'pur/purCooperative/purCooperativeEdit',
    meta: {
      title: '采购订单',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purCooperative/purCooperativeEdit.vue')
  },
  {
    path: '/pur/purCooperative/purCooperativeView',
    name: 'pur/purCooperative/purCooperativeView',
    meta: {
      title: '采购订单',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purCooperative/purCooperativeView.vue')
  },
  {
    path: '/pur/purApplication/applicationView',
    name: 'pur/purApplication/applicationView',
    meta: {
      title: '特价申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purApplication/applicationView.vue')
  },
  {
    path: '/pur/purApplication/applicationView',
    name: 'pur/purApplication/applicationEdit',
    meta: {
      title: '特价申请',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purApplication/applicationEdit.vue')
  },
  // 收货指令
  {
    path: '/pur/purharvestinstruction/purHarvestInstructionEdit',
    name: 'pur/purharvestinstruction/purHarvestInstructionEdit',
    meta: {
      title: '收货指令',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purharvestinstruction/purHarvestInstructionEdit.vue')
  },
  // 境内采购合同
  {
    path: '/pur/purDomesticTrade/purDomesticTradeHeaderEdit',
    name: 'pur/purDomesticTrade/purDomesticTradeHeaderEdit',
    meta: {
      title: '境内采购合同',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purDomesticTrade/purDomesticTradeHeaderEdit.vue')
  },
  // 境外销售合同
  {
    path: '/sale/export/exportSalesContractEdit',
    name: 'sale/export/exportSalesContractEdit',
    meta: {
      title: '境外销售合同',
      fullScreen: true
    },
    component: () => import('@/views/business/views/sale/export/exportSalesContractEdit.vue')
  },
  // 境外销售合同作废
  {
    path: '/sale/export/saleExportContractAbolish',
    name: 'sale/export/saleExportContractAbolish',
    meta: {
      title: '境外销售合同作废',
      fullScreen: true
    },
    component: () => import('@/views/business/views/sale/export/saleExportContractAbolish.vue')
  },
  {
    path: '/sale/delivery/trade/saleTradeDeliveryEdit',
    name: 'sale/delivery/trade/saleTradeDeliveryEdit',
    meta: {
      title: '销售发货详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/sale/delivery/trade/saleTradeDeliveryEdit.vue')
  },
  {
    path: '/sale/trade/saleTradeContractEdit',
    name: 'sale/trade/saleTradeContractEdit',
    meta: {
      title: '境内销售详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/sale/trade/saleTradeContractEdit.vue')
  },
  {
    path: '/eas/invoice/saleInvoiceEdit',
    name: 'eas/invoice/saleInvoiceEdit',
    meta: {
      title: '销售发票详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/eas/sales/invoice/salesInvoiceEdit.vue')
  },
  {
    path: '/eas/bankCreditManagement/edit',
    name: 'eas/bankCreditManagement/edit',
    meta: {
      title: '授信付款申请详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/eas/bankCreditManagement/edit.vue')
  },
  {
    path: '/masterdata/material/materialsmanagement/materialsManagementView',
    name: 'masterdata/material/materialsmanagement/materialsManagementView',
    meta: {
      title: '物料信息管理',
      fullScreen: true
    },
    component: () => import('@/views/business/views/masterdata/material/materialsmanagement/materialsManagementView.vue')
  },
  {
    path: '/masterdata/material/materialsmanagement/materialsManagementEdit',
    name: 'masterdata/material/materialsmanagement/materialsManagementEdit',
    meta: {
      title: '物料信息管理',
      fullScreen: true
    },
    component: () => import('@/views/business/views/masterdata/material/materialsmanagement/materialsManagementEdit.vue')
  },
  {
    path: '/masterdata/supplierRegistration/supplierBaseViews',
    name: 'masterdata/supplierRegistration/supplierBaseView',
    meta: {
      title: '供应商信息管理详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/masterdata/supplierRegistration/supplierBaseView.vue')
  },
  {
    path: '/masterdata/supplierRegistration/supplierBase',
    name: 'masterdata/supplierRegistration/supplierBase',
    meta: {
      title: '供应商信息详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/masterdata/supplierRegistration/supplierBase.vue')
  },
  {
    path: '/customer/customerBase/customerHeaderView',
    name: 'customer/customerBase/customerHeaderView',
    meta: {
      title: '客户信息管理详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/customer/customerBase/customerHeaderView.vue')
  },
  {
    path: '/customer/customerBase/customerHeaderEdit',
    name: 'customer/customerBase/customerHeaderEdit',
    meta: {
      title: '客户信息管理详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/customer/customerBase/customerHeaderEdit.vue')
  },
  {
    path: '/inv/invotheroutwarehouse/invOtherOutWarehouseEdit',
    name: 'inv/invotheroutwarehouse/invOtherOutWarehouseEdit',
    meta: {
      title: '其他出库单详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/inv/invotheroutwarehouse/invOtherOutWarehouseEdit.vue')
  },
  {
    path: '/inv/invotherwarehouse/invOtherWarehouseEdit',
    name: 'inv/invotherwarehouse/invOtherWarehouseEdit',
    meta: {
      title: '其他入库单详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/inv/invotherwarehouse/invOtherWarehouseEdit.vue')
  },
  {
    path: '/inv/purimportwarehouse/purImportWarehouseEdit',
    name: 'inv/purimportwarehouse/purImportWarehouseEdit',
    meta: {
      title: '采购入库单详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/inv/purimportwarehouse/purImportWarehouseEdit.vue')
  },
  // 进口采购合同作废
  {
    path: '/pur/purCooperative/purCooperativeAbolish',
    name: 'pur/purCooperative/purCooperativeAbolish',
    meta: {
      title: '采购订单作废',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purCooperative/purCooperativeAbolish.vue')
  },
  {
    path: '/pur/purApplication/applicationAbolish',
    name: 'pur/purApplication/applicationAbolish',
    meta: {
      title: '特价申请作废',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purApplication/applicationAbolish.vue')
  },
  // 框架协议
  {
    path: '/pur/purframe/purFrameEdit',
    name: 'pur/purframe/purFrameEdit',
    meta: {
      title: '框架协议详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purframe/purFrameEdit.vue')
  },
  // 框架协议
  {
    path: '/pur/purRecordOther/purRecordOtherHeaderEdit',
    name: 'pur/purRecordOther/purRecordOtherHeaderEdit',
    meta: {
      title: '其他采购备案详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/pur/purRecordOther/purRecordOtherHeaderEdit.vue')
  },
  // 调拨出库
  {
    path: '/inv/invtransferorderheader/invTransferOrderHeaderEdit',
    name: 'inv/invtransferorderheader/invTransferOrderHeaderEdit',
    meta: {
      title: '调拨出库',
      fullScreen: true
    },
    component: () => import('@/views/business/views/inv/invtransferorderheader/invTransferOrderHeaderEdit.vue')
  },
  // 调拨入库
  {
    path: '/inv/invtransferstorage/invTransferStorageEdit',
    name: 'inv/invtransferstorage/invTransferStorageEdit',
    meta: {
      title: '调拨入库',
      fullScreen: true
    },
    component: () => import('@/views/business/views/inv/invtransferstorage/invTransferStorageEdit.vue')
  },
  // 运费申请详情
  {
    path: '/logistics/freight/freightApplicationEdit',
    name: 'logistics/freight/freightApplicationEdit',
    meta: {
      title: '运费申请详情',
      fullScreen: true
    },
    component: () => import('@/views/business/views/logistics/freight/freightApplicationEdit.vue')
  },
  // 投保申请详情
  {
    path: '/logistics/insure/insureApplicationEdit',
    name: 'logistics/insure/insureApplicationEdit',
    meta: {
      title: '投保申请详情 ',
      fullScreen: true
    },
    component: () => import('@/views/business/views/logistics/insure/insureApplicationEdit.vue')
  },
  // 报关申请详情
  {
    path: '/logistics/declare/logisticsCustomsDeclarationEdit',
    name: 'logistics/declare/logisticsCustomsDeclarationEdit',
    meta: {
      title: '报关申请详情 ',
      fullScreen: true
    },
    component: () => import('@/views/business/views/logistics/declare/logisticsCustomsDeclarationEdit.vue')
  },
  // 订舱变更详情
  {
    path: '/logistics/booking/bookingNoticeEdit',
    name: 'logistics/booking/bookingNoticeEdit',
    meta: {
      title: '订舱变更详情 ',
      fullScreen: true
    },
    component: () => import('@/views/business/views/logistics/booking/bookingNoticeEdit.vue')
  },
  // 文档预览
  {
    path: '/bus/fileView',
    name: 'fileView',
    meta: {
      title: '预览',
      fullScreen: true
    },
    component: () => import('@/views/business/components/fileView.vue')
  },
  // 内贸采购合同作废
  {
    path: '/pur/purDomesticTrade/purDomesticTradeHeaderAbolish/:param',
    name: 'pur/purDomesticTrade/purDomesticTradeHeaderAbolish',
    title: {i18n: 'purContract.境内采购合同作废详情'},
    meta: {
      keepAlive: true
    },
    component: () => import('@/views/business/views/pur/purDomesticTrade/purDomesticTradeHeaderAbolish.vue')
  },
];
export default businessRouter;
