let EhrUtils = {};
/**
 * 是否为空
 * @param str
 * @returns {boolean}
 */
EhrUtils.isEmpty = (str) => {
    return str === null || str === '' || str === "" || str === 'undefined' || str === undefined;
};
/**
 * 查询待办
 * @param content
 * @param id
 * @returns {Promise}
 */
EhrUtils.getWfTodoById = async (content, id) => {
    return new Promise((resolve, reject) => {
        content.$myHttp({
            method: 'get',
            url: '/microarch/activiti/task/waitfor/' + id,
            params: {}
        }).then(res => {
            resolve(res.data);
        });
    });
};
EhrUtils.addPreZero = (num) => {
  var t = (num + '').length,
    s = '';

  for (var i = 0; i < 4 - t; i++) {
    s += '0';
  }

  return s + num;
};
/**
 * 查询已办
 * @param content
 * @param id
 * @returns {Promise}
 */
EhrUtils.getWfDoneById = async (content, id) => {
    return new Promise((resolve, reject) => {
        content.$myHttp({
            method: 'get',
            url: '/microarch/activiti/task/transated/' + id,
            params: {}
        }).then(res => {
            resolve(res.data);
        });
    });
};
/**
 * 获取审批节点数据
 * @param content
 * @returns {Promise.<*>}
 */
EhrUtils.getWfParams = async (content) => {
    let taskId = content.$route.query['taskId'];
    let task = content.$route.query['task'];
    if (taskId) {
        if (task) {
            if (task == 'todo') {
                return EhrUtils.getWfTodoById(content, taskId);
            } else if (task == 'done') {
                return EhrUtils.getWfDoneById(content, taskId);
            }
        } else {
            return null;
        }
    } else {
        return null;
    }
};

/**
 * 获取流程映射ID
 * @param content
 * @param procDefKey
 * @returns {Promise}
 */
EhrUtils.getProcessMappingId = async (content, procDefKey) => {
    return '';
    // let userInfo = localStorage.getItem('userInfo');
    // let userInfoObj = eval("(" + userInfo + ")");
    // let org = eval("(" + userInfoObj.org + ")");
    // let dept = eval("(" + userInfoObj.dept + ")");
    // return new Promise((resolve) => {
    //     content.$myHttp({
    //         method: 'post',
    //         url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept' + window.suffix,
    //         data: {
    //             'entity': {
    //                 'woCode': procDefKey,
    //                 'orgId': org.orgId,
    //                 'deptId': dept.deptId
    //             }
    //         }
    //     }).then(res => {
    //         if (res.data.ext.code == '2000') {
    //             resolve(res.data.ext.mappingId);
    //         } else {
    //             resolve(null);
    //         }
    //     });
    // });
};
/**
 * 保存表单
 * @param content
 * @param saveUrl
 * @param entity
 * @returns {Promise}
 */
EhrUtils.saveRuleForm = async (content, saveUrl, entity) => {
    return new Promise((resolve) => {
        content.$myHttp({
            method: 'post',
            url: saveUrl,
            data: {
                entity: entity
            }
        }).then(res => {
            resolve(res);
        });
    });
};

/**
 * 更新表单状态
 * @param content
 * @param url
 * @param id
 * @param state
 * @param woCode
 * @returns {Promise}
 */
EhrUtils.updateFormState = async (content, url, id, state, woCode) => {
    return new Promise((resolve) => {
        content.$myHttp({
            method: 'post',
            url: url,
            data: {
                entity: {
                    id: id
                }
            }, params: {
                state: state,
                woCode: woCode
            }
        }).then(res => {
            resolve(res);
        });
    });
};


/**
 * 数字转千分位
 * @param number 数字或字符串数字
 * @returns 转换后的字符串数字
 */
EhrUtils.toThousandBit = (number) => {
    if (number == null || number == '' || number == 'undefined') {
        return number;
    } else {
        number = String(number);
        number = parseFloat(number.replace(/[^\d\.-]/g, ''));
        number = String(number);
        var l_ = number.split('.')[0].split('').reverse();
        var r_ = number.split('.')[1];
        var t_ = '';
        for (var i = 0; i < l_.length; i++) {
            t_ += l_[i] + ((i + 1) % 3 == 0 && (i + 1) != l_.length ? '，' : '');
        }
        var f_ = null;
        if (r_ == null || r_ == '' || r_ == 'undefined') {
            f_ = t_.split('').reverse().join('') + '.';
            f_ = f_.substring(0, f_.lastIndexOf('.'));
        } else {
            f_ = t_.split('').reverse().join('') + '.' + r_;
        }
        return f_;
    }
};

/**
 * 数字移除千分位
 * @param number 数字或字符串数字
 * @returns 移除后的字符串数字
 */
EhrUtils.removeThousandBit = (number) => {// 去千分位
    if (number == null || number == '' || number == 'undefined') {
        return number;
    } else {
        number = String(number);
        number = number.replace(/，/gi, '');
        return number;
    }
};

/**
 * 通过字典value获取字典label
 * @param list
 * @param value
 * @returns {string}
 */
EhrUtils.getDictItemLabelByValue = (list, value) => {
    for (let idx in list) {
        let item = list[idx];
        if (item.value == value) {
            return item.label;
        }
    }
    return '';
};
/**
 * 通过主键Id获取审批记录
 * @param content
 * @param businessKey
 * @returns {Promise}
 */
EhrUtils.findCurrentTaskList = async (content, businessKey, woCode) => {
    const isEmpty = EhrUtils.isEmpty(businessKey);
    if (isEmpty) {
        return [];
    }
    return new Promise((resolve) => {
        content.$myHttp({
            method: 'post',
            url: '/hrhi/common/findCurrentTaskList',
            data: {
                entity: {}
            }, params: {
                businessKey: businessKey,
                woCode: woCode
            }
        }).then(res => {
            resolve(res.data);
        });
    });
};
/**
 * 获取下一个主职结束时间
 * @param content
 * @param psnDocId
 * @returns {Promise.<*>}
 */
EhrUtils.getNextMainJobEndDate = async (content, psnDocId) => {
    const isEmpty = EhrUtils.isEmpty(psnDocId);
    if (isEmpty) {
        return null;
    }
    return new Promise((resolve) => {
        content.$myHttp({
            method: 'post',
            url: '/hrhi/transfer/getNextMainJobEndDate',
            data: {
                entity: {}
            }, params: {
                psnDocId: psnDocId,
            }
        }).then(res => {
            resolve(res.data);
        });
    });
};
/**
 * 获取当前登录用户所有角色下的资源编码
 * @param content
 * @param userId
 * @returns {Promise}
 */
EhrUtils.findUserRoleResourceMap = async (content, userId) => {
    return new Promise((resolve) => {
        content.$myHttp({
            method: 'post',
            url: '/microarch/sys/syscommon/findUserRoleResourceList',
            data: {},
            params: {
                userId: userId,
            }
        }).then(res => {
            let map = {};
            if (res) {
                let list = res.data;
                if (list && list.length > 0) {
                    list.forEach((item) => {
                        map[item.resCode] = item;
                    });
                }
            }
            resolve(map);
        });
    });
};
/**
 * 确认归档
 * @param content
 * @param url
 * @param businessKey
 * @returns {Promise}
 */
EhrUtils.confirmHandle = async (content, url, businessKey, params) => {
    return new Promise((resolve) => {
        content.$myHttp({
            method: 'post',
            url: url,
            data: {
                entity: {
                    id: businessKey
                }
            },
            params: params
        }).then(res => {
            resolve(res.data.entity);
        });
    });
};
/**
 * 查询全部职位下拉列表
 * @param content
 * @param jobEntity
 * @param callBack
 */
EhrUtils.findJobSelectListAll = (content, data, params, callBack) => {
    content.$myHttp({
        method: 'post',
        url: '/hrhi/omjob/findJobSelectListAll',
        data: data,
        params: params
    }).then(res => {
        if (res) {
            callBack(res.entity.data);
        }
    });
};
/**
 * 远程查询
 * @param content
 * @param remoteUrl
 * @param data
 * @param params
 * @param callBack
 */
EhrUtils.remoteQuery = (content, remoteUrl, data, params, callBack) => {
    content.$myHttp({
        method: 'post',
        url: remoteUrl,
        data: data,
        params: params
    }).then(res => {
        if (res) {
            callBack(res.data.entity);
        } else {
            callBack([]);
        }
    });
};
/**
 * 保存
 * @param content
 * @param url
 * @param entity
 * @returns {Promise}
 */
EhrUtils.commonSave = async (content, url, entity) => {
    return new Promise((resolve) => {
        content.$myHttp({
            method: 'post',
            url: url,
            data: {
                entity: entity
            }
        }).then(res => {
            resolve(res.data.entity);
        });
    });
};
/**
 * 表单校验
 * @param content
 * @param fromRef
 * @returns {Promise}
 */
EhrUtils.validateForm = async (content, fromRef) => {
    return new Promise((resolve) => {
        content.$refs[fromRef].validate((valida) => {
            resolve(valida);
        });
    });
};
/**
 * 判断两个对象是否完全相等
 * @param o1
 * @param o2
 * @returns {boolean}
 */
EhrUtils.objectIsEqual = (o1, o2) => {
    if ((typeof o1) != 'object' || (typeof o2) != 'object') {
        return false;
    }
    let props1 = Object.getOwnPropertyNames(o1);
    let props2 = Object.getOwnPropertyNames(o2);
    if (props1.length != props2.length) {
        return false;
    }
    for (let prop in props1) {
        if (o1[prop] != o2[prop]) {
            return false;
        }
    }
    return true;
};
/**
 * 获取url中请求的参数
 * @param href
 * @returns {{}}
 */
EhrUtils.getRequestParams = (href) => {
    let theRequest = {};
    if (href.indexOf("?") != -1) {
        var requestArray = href.substr(href.indexOf('?') + 1, href.length).split("&");
        for (var i = 0; i < requestArray.length; i++) {
            theRequest[requestArray[i].split("=")[0]] = decodeURIComponent(requestArray[i].split("=")[1]);
        }
    }
    return theRequest;
};

/**
 * 日期加指定天数
 * 例子（获取当前日期向前推3天的日期）：EhrUtils.subtractDate(new Date(), 'day', 3)
 * @param date 要处理的日期对象(Date())
 * @param type 要处理的类型(year,month,day)
 * @param num 相加的数量
 */
EhrUtils.subtractDate = (date, type, num) => {
    var srcDate = new Date(date.getTime());
    if (type == 'year') {
        date.setFullYear(date.getFullYear() + num);
    } else if (type == 'month') {
        date.setMonth(date.getMonth() + num);
    } else if (type == 'day') {
        date.setDate(date.getDate() + num);
    }
    if (((type == 'year' || type == 'month') && date.getDate() == srcDate.getDate())
        || type == 'day') {
        return date;
    }

    // 原始日期当前月的最大日期 比 处理后当前月的最大日期大(即原始日期处于当前月最大日期)
    // 处理后应为2月，实际处理后为3月
    if (date.getMonth() == 2) {
        // 闰年
        if ((date.getFullYear() % 4 == 0) && (date.getFullYear() % 100 != 0 || date.getFullYear() % 400 == 0)) {
            date.setDate(29);
        } else {
            date.setDate(28);
        }
        date.setMonth(1);

        // 处理后应为4 6 9 11月，实际处理后为5 7 10 12月
    } else if (date.getMonth() == 4 || date.getMonth() == 6 || date.getMonth() == 9 || date.getMonth() == 11) {
        date.setDate(30);
        date.setMonth(date.getMonth() - 1);
    }
    return date;
};
// 日期转字符串
EhrUtils.dateToString = (date) => {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString();
    var day = (date.getDate()).toString();
    if (month.length == 1) {
        month = "0" + month;
    }
    if (day.length == 1) {
        day = "0" + day;
    }
    var dateTime = year + "-" + month + "-" + day;
    return dateTime;
};
//判断指定日期和当前日期大小
EhrUtils.judgeDate = (date) => {
    return new Date().getTime() - new Date(date).getTime();
};
/**
 * 判断两个日期的大小
 * @param var1
 * @param var2
 * @returns {number}
 * 等于0：var1 等于 var2
 * 大于0：var1 大于 var2
 * 小于0：var1 小于 var2
 */
EhrUtils.dateCompare = (var1, var2) => {
    return var1.getTime() - var2.getTime();
};

EhrUtils.getNowDate=()=>{
  var nowDate = new Date();
  var year = nowDate.getFullYear();
  var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
    : nowDate.getMonth() + 1;
  var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
    .getDate();
    return  year + "-" + month + "-" + day;

};
/**
 * 获取系统配置
 * @param content
 * @param saveUrl
 * @param entity
 * @returns {Promise}
 */
EhrUtils.getSysConfigData = (content) => {
  return new Promise((resolve, reject) => {
    content.$myHttp({
    method: 'get',
    url: '/hrhi/common/getSysConfigData',
    data: {}
  }).then((res) => {
    if (res) {
      if (res.data) {
        if (res.data.entity) {
          resolve(res.data.entity);
        } else {
          resolve({});
        }
      } else {
        resolve({});
      }
    } else {
      resolve({});
}
}, (error) => {
    reject(error);
  });
});
};
export default EhrUtils;
