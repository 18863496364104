<template>
  <div>
    <van-tabbar v-model="active"
                class="tab"
                style="z-index:999">
      <van-tabbar-item to="/notice/noticeList">
        <span>{{ $t('Tab.通知') }}</span>
        <template #icon="props">
          <i class="iconfont icon-tongzhi-zhengchang" v-if="active != 0"></i>
          <i class="iconfont icon-tongzhi-dianji" v-else></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/Home">
        <span>{{ $t('Tab.自助') }}</span>
        <template #icon="props">
          <i class="iconfont icon-zizhu-zhengchang" v-if="active != 1"></i>
          <i class="iconfont icon-zizhu-dianji" v-else></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/news/newsList">
        <span>{{ $t('Tab.消息') }}</span>
        <template #icon="props">
          <i class="iconfont icon-xiaoxi-zhengchang" v-if="active != 2"></i>
          <i class="iconfont icon-xiaoxi--dianji" v-else></i>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 1
    };
  },
  watch: {
    $route (to, from) {
      switch (to.fullPath) {
        case '/notice/noticeList':
          this.active = 0;
          break;
        case '/Home':
          this.active = 1;
          break;
        case '/news/newsList':
          this.active = 2;
          break;
        default:
          this.active = 1;
      }
    }
  }
};
</script>

<style>
.van-tabbar--fixed {
  height: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(113, 113, 113, 0.16);
}
.van-tabbar--fixed .van-tabbar-item__icon i {
  width: 19px;
  height: 19px;
  font-size: 19px;
}
.van-tabbar--fixed .van-tabbar-item.van-tabbar-item--active {
  color: rgba(67, 120, 190, 1);
}
.van-tabbar--fixed .van-tabbar-item:nth-child(2) .van-tabbar-item__icon {
  margin-top: -30px;
  width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow:0px -3px 6px rgba(113,113,113,0.16);
}
.van-tabbar--fixed .van-tabbar-item:nth-child(2) .van-tabbar-item__icon i {
  width: 25px;
  height: 25px;
  font-size: 25px;
}
</style>
