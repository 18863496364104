<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" @getMessage="getMessage"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" @getMessage="getMessage"></router-view>

    <sys-tab :mesCount="mesCount" v-if="VUE_APP_TAB === 'sys' && $route.meta" v-show="!$route.meta.fullScreen"/>
    <business-tab v-else v-show="$route.meta.title && $route.meta.fullScreen !== true"/>
  </div>
</template>

<script>
  import sysTab from '@/views/sys/Tab/Tab';
  import businessTab from '@/views/business/views/Tab/Tab';

  export default {
    components: {
      sysTab,
      businessTab
    },
    data() {
      return {
        VUE_APP_TAB: 'sys',
        mesCount: 0
      };
    },
    methods: {
      getMessage() {
        if (!sessionStorage.getItem('userName')) return;
        this.$myHttp({
          method: 'GET',
          url: '/microarch/sys/message/countsUnread/' + sessionStorage.getItem('userName'),
          data: {}
        }).then(res => {
          this.mesCount = res.data;
        });
      }
    },
    created() {
      this.VUE_APP_TAB = process.env.VUE_APP_TAB;
      this.getMessage();
      console.log('process.env.VUE_APP_TAB', process.env.VUE_APP_TAB);
    }
  };
</script>
<style lang="stylus">
html, body, #app
  width 100%
  height 100%

#github
  position fixed
  top 0
  right 60px
  z-index 999

.errormessage {
  color red
  font-size 12px
  padding 5px 16px
}

.detailLine {
  border-bottom: 1px solid #e6e6e6
  padding-bottom: 5px
}

.items {
  margin-bottom: 10px;
  /*border-bottom: 1px solid #e6e6e6;*/
  /*padding-bottom: 10px;*/
  display: flex;
  align-items: center;
  border-bottom:1px solid #eee;
  padding-bottom 5px;

  h5 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 100;
    font-size: 14px;
    color: #333333;
    width: 100px;
    /*font-weight: ;*/
  }

  p {
    /*margin-top:10px;*/
    margin-left: 20px;
    font-size: 14px;
    color: #666;
    font-weight: bold;
    overflow: hidden;
    flex: 1;
    text-align right;
    -webkit-word-break: break-all;
    -moz-word-break: break-all;
    -ms-word-break: break-all;
    -o-word-break: break-all;
    word-break: break-all;
  }
}

.splitTitle {
  font-size: smaller;
  color: #464545;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-top: 20px;
}
</style>
